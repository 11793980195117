import { Component } from '@angular/core';
import { MenuItem, UserMenuItem } from '@devop/ngx-metronic/base-layout';
import { CommonLayoutComponent } from '../common-layout.component';
import { Icon } from '@project/admin/shared/icon';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent extends CommonLayoutComponent {
  Icon = Icon;
  asideMenu: MenuItem[] = [
    {
      title: 'Pradžia',
      svg: 'assets/media/metronic/icons/duotune/general/gen008.svg',
      link: '/dashboard',
    },
    {
      title: 'Užsakymai',
      svg: 'assets/media/metronic/icons/duotune/communication/com013.svg',
      children: [
        {
          title: 'Užsakymai',
          svg: 'assets/media/metronic/icons/duotune/finance/fin002.svg',
          link: '/order',
        },

        {
          title: 'Sąskaitos',
          svg: 'assets/media/metronic/icons/duotune/finance/fin002.svg',
          link: '/invoice',
        },
      ],
    },
    {
      title: 'Katalogas',
      svg: 'assets/media/metronic/icons/duotune/communication/com013.svg',
      children: [
        {
          title: 'Produktai',
          svg: 'assets/media/metronic/icons/duotune/finance/fin002.svg',
          link: '/product',
        },

        {
          title: 'Sąvybės',
          svg: 'assets/media/metronic/icons/duotune/finance/fin002.svg',
          link: '/attribute',
        },
      ],
    },
    {
      title: 'Skaičiuoklė',
      svg: 'assets/media/metronic/icons/duotune/finance/fin002.svg',
      link: '/calculator',
    },
    {
      title: 'Vartotojai',
      svg: 'assets/media/metronic/icons/duotune/communication/com013.svg',
      link: 'project',
      children: [
        {
          title: 'Vartotojų sąrašas',
        },
        {
          title: 'Vartotojų veiklos įrašai',
          link: 'a',
        },
      ],
    },
    {
      title: 'Nustatymai',
      svg: 'assets/media/metronic/icons/duotune/general/gen019.svg',
      children: [
        {
          title: 'El. Paštas',
          link: 'OK',
          children: [
            {
              title: 'Pašto serveris',
              link: 'a',
            },
            {
              title: 'Šablonai',
              link: 'a',
            },
          ],
        },
      ],
    },
  ];

  topMenu: MenuItem[] = [
    {
      title: 'Valdymo skydelis',
      link: '/',
    },
    {
      title: 'Klientų zona',
      link: '/client',
    },
  ];

  userMenu: UserMenuItem[] = [
    {
      id: 'logout',
      label: 'Atsijungti',
    },
  ];
  asideMenuVisible = true;

  toggleStartDrawerMinimised() {
    this.layoutService.sidebarMinimize = !this.layoutService.sidebarMinimize;
    this.layoutService.update();
  }
}
