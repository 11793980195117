<div class="d-flex flex-column flex-root app-root">
  <div class="app-page flex-column flex-column-fluid">
    <div class="app-header">
      <div
        class="app-container container-fluid d-flex align-items-stretch justify-content-between"
      >
        <div
          *ngIf="asideMenuVisible"
          class="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2"
          title="Show sidebar menu"
        >
          <div
            class="btn btn-icon btn-active-color-primary w-35px h-35px"
            (click)="toggleStartDrawer()"
          >
            <svg-icon
              class="svg-icon svg-icon-2 rotate-180"
              [src]="Icon.abs015"
            ></svg-icon>
          </div>
        </div>
        <div
          class="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
        >
          <met-top-menu
            class="app-header-menu app-header-mobile-drawer align-items-stretch"
            [menu]="topMenu"
          ></met-top-menu>
          <div class="app-navbar flex-shrink-0">
            <div class="d-flex align-items-stretch flex-shrink-0">
              <div class="d-flex align-items-center ms-1 ms-lg-3">
                <met-header-user
                  *ngIf="user"
                  [avatar]="user.avatarUrl"
                  [name]="user.firstName + ' ' + user.lastName"
                  [badge]="user.role ? { label: user.role } : null"
                  [subtitle]="''"
                  [menu]="userMenu"
                  (menuItemClick)="onUserMenuItemClick($event)"
                ></met-header-user>
              </div>
              <div
                class="app-navbar-item d-lg-none ms-2 me-n3"
                title="Show header menu"
                (click)="toggleEndDrawer()"
              >
                <div
                  class="btn btn-icon btn-active-color-primary w-30px h-30px w-md-35px h-md-35px"
                >
                  <svg-icon
                    class="svg-icon svg-icon-2 svg-icon-md-1"
                    [src]="Icon.txt001"
                  ></svg-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-wrapper flex-column flex-row-fluid">
      <met-aside
        *ngIf="asideMenuVisible"
        class="app-sidebar flex-column"
        [class.drawer]="(lg$ | async) !== true"
        [class.drawer-start]="(lg$ | async) !== true"
        [on]="startDrawer$.value"
      >
        <div class="app-sidebar-logo px-6">
          <a href="#">
            <img
              alt="Logo"
              src="assets/media/metronic/logos/default-dark.svg"
              class="h-25px app-sidebar-logo-default"
            />
            <img
              alt="Logo"
              src="assets/media/metronic/logos/default-small.svg"
              class="h-20px app-sidebar-logo-minimize"
            />
          </a>
          <div
            class="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate"
            [class.active]="layoutService.sidebarMinimize"
            (click)="toggleStartDrawerMinimised()"
          >
            <svg-icon
              class="svg-icon svg-icon-2 rotate-180"
              [src]="Icon.arr079"
            ></svg-icon>
          </div>
        </div>
        <met-side-menu
          [menu]="asideMenu"
          (itemClick)="closeDrawer()"
          class="app-sidebar-menu overflow-hidden flex-column-fluid"
        ></met-side-menu>
      </met-aside>
      <met-aside
        *ngIf="(lg$ | async) !== true"
        class="app-header-menu align-items-stretch flex-column drawer drawer-end min-w-250px"
        [on]="endDrawer$.value"
      >
        <div
          class="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0"
        >
          <met-menu-item
            *ngFor="let menu of topMenu"
            [item]="menu"
            (itemClick)="closeDrawer()"
          ></met-menu-item>
        </div>
      </met-aside>
      <div class="app-main flex-column flex-row-fluid">
        <router-outlet></router-outlet>
        <met-footer class="app-footer"></met-footer>
      </div>
    </div>
  </div>
</div>
<div
  style="z-index: 105"
  class="drawer-overlay"
  *ngIf="drawerIsOpen$ | async"
  (click)="closeDrawer()"
></div>
