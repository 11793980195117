import { isPlatformServer } from '@angular/common';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { InjectionToken, NgModule, Optional, PLATFORM_ID } from '@angular/core';
import { ApolloLink, InMemoryCache, split } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { extractFiles } from 'extract-files';
import { OperationDefinitionNode } from 'graphql';

export const API_URL = new InjectionToken<string>('API_URL');

@NgModule({
  imports: [HttpClientModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink, platformId: string, apiUrl?: string) => {
        if (!apiUrl) {
          return;
        }
        if (isPlatformServer(platformId)) {
          const http = httpLink.create({
            uri: apiUrl,
            extractFiles,
          });
          return {
            cache: new InMemoryCache(),
            link: ApolloLink.from([http]),
          };
        }
        const http = httpLink.create({
          uri: apiUrl,
          extractFiles,
        });
        const ws = new WebSocketLink({
          uri: apiUrl.replace('http', 'ws').replace('https', 'wss'),
          options: {
            reconnect: true,
          },
        });
        const authMiddleware = new ApolloLink(
          (operation: any, forward: any) => {
            const url = new URLSearchParams(location.search);
            const token = url.get('token');
            if (!token) {
              return forward(operation);
            }
            operation.setContext({
              headers: new HttpHeaders().set(
                'authorization',
                'Bearer ' + token
              ),
            });
            return forward(operation);
          }
        );
        return {
          cache: new InMemoryCache(),
          link: ApolloLink.from([
            authMiddleware,
            split(
              // split based on operation type
              ({ query }) => {
                const { kind, operation } = getMainDefinition(
                  query
                ) as OperationDefinitionNode;
                return (
                  kind === 'OperationDefinition' && operation === 'subscription'
                );
              },
              ws,
              http
            ),
          ]),
          defaultOptions: {
            query: {
              fetchPolicy: 'cache-and-network',
              errorPolicy: 'all',
            },
          },
        };
      },
      deps: [HttpLink, PLATFORM_ID, [new Optional(), API_URL]],
    },
  ],
})
export class GraphQLModule {}
