import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultLayoutComponent } from './default-layout.component';
import { RouterModule } from '@angular/router';
import { BaseLayoutModule } from '@devop/ngx-metronic/base-layout';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [DefaultLayoutComponent],
  exports: [DefaultLayoutComponent],
  imports: [CommonModule, BaseLayoutModule, RouterModule, AngularSvgIconModule],
})
export class DefaultLayoutModule {}
