import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth.guard';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        pathMatch: 'full',
        loadChildren: () =>
          import('./view/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'invoice',
        loadChildren: () =>
          import('./view/invoice/invoice.module').then((m) => m.InvoiceModule),
      },
    ],
  },
  {
    path: 'e-invoice',
    loadChildren: () =>
      import('./e-invoice/e-invoice.module').then((m) => m.EInvoiceModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./view/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./view/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'client',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./client/client.module').then((m) => m.ClientModule),
  },
  {
    path: 'test',
    loadChildren: () =>
      import('./view/test/test.module').then((m) => m.TestModule),
  },
  {
    path: 'e',
    loadChildren: () =>
      import('./view/error/error.module').then((m) => m.ErrorModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
