import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { API_URL, GraphQLModule } from './graphql/graphql.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DefaultLayoutModule } from './layout/default-layout/default-layout.module';
import { environment } from '../environments/environment';
import { UPLOAD_CONFIG_TOKEN } from './service/upload.service';
import '@angular/common/locales/global/lt';
import { HttpClient } from '@angular/common/http';
import { IntlService } from './service/intl.service';

function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DefaultLayoutModule,
    AppRoutingModule,
    AngularSvgIconModule.forRoot(),
    RouterModule.forRoot([], { initialNavigation: 'enabledBlocking' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    GraphQLModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [IntlService],
      useFactory: (intl: IntlService) => intl.lang,
    },
    {
      provide: API_URL,
      useValue: environment.api,
    },
    {
      provide: UPLOAD_CONFIG_TOKEN,
      useValue: {
        maxUploadSize: 1024 * 1024 * 200,
        iconForExtension: (ext: any) => {
          return `/assets/media/svg/files/${ext ?? 'generic'}.svg`;
        },
        allowedExtensions: [
          'png',
          'jpg',
          'gif',
          'mp3',
          'svg',
          'doc',
          'pdf',
          'gif',
          'psd',
          'docx',
          'xml',
          'txt',
          'html',
          'json',
          'zip',
          'gz',
          'mp4',
          'mkv',
          '7z',
          'm4a',
          'xls',
        ],
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
