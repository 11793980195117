import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IntlService } from './service/intl.service';

@Component({
  selector: 'app-main',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(translate: TranslateService, intl: IntlService) {
    translate.setDefaultLang(intl.lang);
    translate.use(intl.lang);
  }
}
